<template>
  <H5 v-if="isMobile" />
  <PC v-else />
</template>

<script lang="ts" setup>
import { ENUM_PageName } from '~/enums'

definePageMeta({
  pageCategory: ENUM_PageName.Home,
  hideNavBar: true,
})

const commonStore = useCommonStore()
const userStore = useUserStore()

const isMobile = useMobileByScreen()
const { fetchCurrentCity } = useCurrentCity()

const H5 = defineAsyncComponent(() => import('~/pages/mobile/index.vue'))
const PC = defineAsyncComponent(() => import('~/pages/desktop/index.vue'))

useAutoScrollTop()

const { queryCategories, queryRecentViewd } = useSearchResult()
await Promise.all([queryCategories(), queryRecentViewd(), fetchCurrentCity()])
if (!commonStore.commonData.cityInfo?.attrDestinationList?.length) {
  commonStore.getCityList()
}

watch(() => userStore.userInfo.currency, () => {
  queryRecentViewd()
})
</script>
